<template>
    <div id="eggs" class="eggsVertical">
        
        <ul class="eggsVertical">
            <li v-for="(item, index) in 3" :key="index" @click="drown(index)">
                <div v-show="hammer && (active == index)" :class="(hammer && (active == index)) ? 'hammer hammerClick' : 'hammer'">
                    <img src="../images/hammer.png"/>
                </div>
                <div class="bottom">
                    <img src="../images/bottom.png"/>
                </div>
                <div class="content"  v-if="!smashOpen && (active == index)">
                    <img v-show="smashOpen" class="front" src="../images/front.png"/>
                    <img v-show="!smashOpen" class="behind" src="../images/behind.png"/>
                </div>
                <div class="content" v-else>
                    <img class="front" src="../images/front.png"/>
                </div>

                <img v-if="ray && (active == index)" class="ray" src="../images/egg_ray.png">
                <img v-if="blast && (active == index)" :class="blastName(index)" src="../images/blast.gif">
            </li>
        </ul>

        <div class="history historyVertical">
            <h4>中奖名单</h4>
            <div>
                <div>
                    <p>用户名</p>
                    <p>砸得奖品</p>
                </div>
                <ul :class="{scroll: historyAnimate}" v-if="history">
                    <li v-for="(item, index) in history" :key="index">
                        <p>{{item.membername}}</p>
                        <p>砸出 {{item.poductname}}</p>
                    </li>
                </ul>
            </div>
        </div>

        <div class="giftList giftListVertical">
            <div>
                <h4>奖品清单</h4>
                <div class="list">
                    <ul :class="{scroll: animate}">
                        <li v-for="(item, index) in list" :key="index">
                            <img :src="item.ImgUrl"/>
                            <p>{{item.MallProductName}}</p>
                        </li>
                    </ul>

                </div>
            </div>
        </div>

        <van-popup v-model="popupShow" class="popupShow popupShowVertical">
            <div class="box giftShow" @click="close(1)">
                <div class="code">
                    <div>
                        <img :src="activeGift.ImgUrl"/>
                        <p>{{activeGift.MallProductName}}</p>
                    </div>
                </div>
                <div class="product">
                    <img :src="giftCode"/>
                    <div>
                        <p>哇哦！人有精气神，好运从天降！恭喜你，你被大奖砸中啦！</p>
                        <p>快来扫码领取吧</p>
                    </div>
                </div>
            </div>
        </van-popup>

        <van-popup v-model="nogiftShow" class="popupShow popupShowVertical" @close="close">
            <div class="box" @click="close(2)">
                <div class="code">
                    <div>
                        <img src="../images/nogift.png"/>
                    </div>
                </div>
                <div class="product nogiftVertical">
                    <div>
                        <p>很遗憾！</p>
                        <p>您没有中奖哦~</p>
                    </div>
                </div>
            </div>
        </van-popup>

         <audio src="../images/bg.mp3" loop ref="bg" class="myAudio"></audio>
         <audio src="../images/hammer.mp3" ref="hammer" class="myAudio"></audio>
         <audio src="../images/gift.mp3" ref="gift" class="myAudio"></audio>
         <audio src="../images/nogift.mp3" ref="nogift" class="myAudio"></audio>
       
       <button :class="playBgMp3 ? 'audioBtn audio02' : 'audioBtn audio01'" @click="play()"></button>
    </div>
</template>

<script>
import {luckyDrawGift, giftQrcode, giftHistory, jsSing} from '../api/eggs'
import wx from 'weixin-js-sdk'
export default {
    data () {
        return {
            empid: '',
            animate: false, //清单滚动
            historyAnimate: false, //历史记录滚动
            active: '',
            list:[],
            smashOpen: true, //蛋未砸开
            ray: false, //光
            blast: false, //爆炸
            isDrown: true, //是否点击
            hammer: false, //锤子
            activeGift: '',
            giftCode: '',
            popupShow: false,// 中奖弹窗
            nogiftShow: false, //未中奖弹窗
            history: [
                {membername: '赵**', poductname:'长嘴狗除醛玩偶'},
                {membername: '王**', poductname:'除醛碳包'},
                {membername: '孙**', poductname:'长嘴狗除醛玩偶'},
                {membername: '周**', poductname:'新房清洁套装'},
                {membername: '陈**', poductname:'除醛碳包'},
                {membername: '赵**', poductname:'长嘴狗除醛玩偶'},
            ],
            isVertical: '', //是否为竖屏
            playBgMp3: false,
        }
    },
    mounted () {
        const {empid} = this.$route.query;
        this.empid = empid;
        this.wxConfig();
        this.getGift();
        // this.getHistory();
        setInterval(this.historyScroll, 1000);
        
    },
    computed: {
        blastName () {
            return (index) => {
                switch(index) {
                    case 0: return 'blast blast0';
                    case 1: return 'blast blast1';
                    case 2: return 'blast blast2';
                }
            }
        }
    },
    methods: {
        /* 历史记录无缝滚动 */
        historyScroll () {
            this.historyAnimate = true;
            setTimeout(() => {
                this.history.push(this.history[0]);
                this.history.shift();
                this.historyAnimate = false;
            }, 500)
        },

        /* 无缝滚动 */
        listScroll () {
            this.animate = true;
            setTimeout(() => {
                this.list.push(this.list[0]);
                this.list.shift();
                this.animate = false;
            }, 500)
        },

        /* 获取参与抽奖的礼品 */
        async getGift () {
            const {empid} = this;
            let res = await luckyDrawGift({empid});
            console.log(res);
            const {status, data, message} = res;
            if (status == 0) {
                this.list = data.data;
            } else {
                this.$toast(message);
            }
        },

        /* 砸下动作 */
        drown (index) {
            const {isDrown} = this;
            
            if (isDrown) {
                this.hammer = true;
                this.active = index;
                this.timeOut(() => {
                    console.log('123');
                    this.isDrown = false;
                    this.smashOpen = false;
                    this.hammer = false;
                    this.$refs.hammer.play();
                    this.blast = true;
                    this.ray = true;
                }, 1000)
                .then(() => {
                    this.timeOut(() => {
                        this.luckDraw();
                        this.blast = false;
                    },1000);
                })
            }
        },

        timeOut (cb, time) {
            return new Promise((resolve) => {
                let a = setTimeout(() => {
                    cb && cb();
                    clearTimeout(a);
                    resolve();
                }, time || 0);
            })
        },

        /* 抽奖 */
        async luckDraw() {
            const {list, empid} = this;
            let num = parseInt(3*Math.random());
            this.activeGift = this.list[num];
            let arr = {
                empid,
                ProductId: this.activeGift.MallProductId
            }
            let res = await giftQrcode(arr);
            console.log(res);
            const {status, data} = res;
            if (status == 0) {
                this.giftCode = data.qrcode;
                this.$refs.gift.play();
                this.popupShow = true;
            } else{
                this.nogiftShow = true;
                this.$refs.nogift.play();
                this.active = '';
                this.smashOpen = true;
                this.ray = false;
                this.isDrown = true;
                this.hammer = false;
                this.activeGift = '';
                this.giftCode = '';
            }
        },

        /* 取消弹窗 */
        close (num) {
            num == 1 ?
            this.popupShow = false:
            this.nogiftShow = false;
            this.active = '';
            this.smashOpen = true;
            this.ray = false;
            this.isDrown = true;
            this.hammer = false;
            this.activeGift = '';
            this.giftCode = '';
            // this.getHistory();
        },

        /* 获取抽奖记录 */
        async getHistory () {
            const {empid} = this;
            let res = await giftHistory({empid});
            console.log(res);
            const {status, data, message} = res;
            if (status == 0) {
                this.history = data;
                this.history.map(item => {
                    item.body = JSON.parse(item.body);
                });
                console.log(this.history);
            }
        },

        play () {
            const {playBgMp3} = this;
            if (playBgMp3) {
                this.playBgMp3 = false;
                this.$refs.bg.pause();
            } else {
                this.playBgMp3 = true;
                this.$refs.bg.play();
            }
        },

        /* 获取微信签名 */
        async wxConfig () {
            let res = await jsSing();
            console.log(res);
            const {code, data, msg} = res;
            if (code == 1) {
                const {appId, nonceStr, signature, timestamp} = data;
                wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId, // 必填，公众号的唯一标识
                timestamp, // 必填，生成签名的时间戳
                nonceStr, // 必填，生成签名的随机串
                signature,// 必填，签名
                jsApiList: ['hideOptionMenu'] // 必填，需要使用的JS接口列表
                });

                wx.ready(() => {
                console.log('111');
                })
            } 
        }
    }
}
</script>

<style lang="less" src="@/style/wxeggs.less">

</style>